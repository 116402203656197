var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "title": "Pilih Tanggal Laporan"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Awal"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tgl_awal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_awal", $$v);
      },
      expression: "form.tgl_awal"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Akhir"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tgl_akhir,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_akhir", $$v);
      },
      expression: "form.tgl_akhir"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "--"
    }
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.isValidForm,
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Lihat Laporan")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }